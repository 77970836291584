// SparkCognition Proprietary and Confidential.
// ©SparkCognition 2022-2023. All Rights Reserved.
import { Flex, OverflowableText, OverlayError } from '@core-ui/components';
import { FallbackProps } from '@core-ui/components/dist/components/ErrorBoundary/ErrorBoundary';
import { Theme } from '@emotion/react';

// eslint-disable-next-line prefer-destructuring
export const NODE_ENV = process.env.NODE_ENV;

export const ErrorFallback = (props: FallbackProps) => {
  const { error, errorInfo } = props;
  return (
    <Flex css={{ width: '100%', height: '100%' }}>
      <OverlayError
        testId="overlay-error"
        label={
          (NODE_ENV === 'development' || NODE_ENV === 'test') && (
            <>
              <Flex>{error}</Flex>
              <OverflowableText
                css={(theme: Theme) => ({
                  fontSize: 10,
                  color: theme.neutral.v('-3', '+3'),
                })}
                lineClamp={30}>
                {errorInfo}
              </OverflowableText>
            </>
          )
        }
      />
    </Flex>
  );
};
