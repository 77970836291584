// SparkCognition Proprietary and Confidential.
// ©SparkCognition 2022-2024. All Rights Reserved.
import { prefixConsole, prefixStorage } from '@core-ui/client';
import {
  Activity,
  AppActivity,
  AppDrawer,
  AppGlobals,
  ErrorBoundary,
  Modal,
  useNotifications,
} from '@core-ui/components';
import { useTheme } from '@emotion/react';
import BrightnessMediumRoundedIcon from '@mui/icons-material/BrightnessMediumRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import {
  AppNav,
  AppNavConfig,
  Context as AppNavContext,
  retraceAppNavRouting,
  useAppNavRouting,
} from 'app-navigation-ui';
import { Location } from 'history';
import { observer } from 'mobx-react';
import { ModelBehaviorConfig } from 'model-behavior-mfe';
import { ModelDetailsConfig } from 'model-details-mfe';
import { Suspense, lazy, useContext, useEffect, useMemo } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router';
import { StoreContext, domainName } from '../store';
import {
  alFetch,
  appComponents,
  eventsFetch,
  fhFetch,
  kdFetch,
  layoutFetch,
  mbFetch,
  modelDetailsFetch,
  phFetch,
  raFetch,
  sdFetch,
  kalFetch,
  userPrefFetch,
} from './Contexts';
import { APPS_HIERARCHY } from './_/Hierarchy';
import {
  DashboardConfig,
  LayoutConfig,
  Settings,
  SystemDiagramConfig,
  getName,
  hasId,
} from './_/helpers';
import { INTERCOM_APP_ID, useSetupIntercom } from './_/useSetupIntercom';
import { AutoLogout } from './components/AutoLogout';
import { ErrorFallback } from './components/ErrorFallback/ErrorFallback';
import {
  NavbarMenu,
  processMenu,
  useMonitorSettingsItems,
  useReliabilitySettingsItems,
} from './components/NavbarMenu/NavbarMenu';
import { AccessDenied } from './pages/AccessDenied';
import { NotFound } from './pages/NotFound';
import {
  MOCK_REF_VALUE,
  actualData,
  assetDimensionsList,
  projectedData,
} from './test/OptimizerMockData';

export const AuthenticatedApp = observer(() => {
  const theme = useTheme();
  const RecordsAnalysisContext = lazy(() =>
    import('records-analysis-mfe').then(module => ({
      default: module.Context,
    })),
  );
  const UserPreferencesContext = lazy(() =>
    import('user-preferences-mfe').then(module => ({
      default: module.Context,
    })),
  );
  const SystemDiagramContext = lazy(() =>
    import('system-diagram-mfe').then(module => ({
      default: module.Context,
    })),
  );
  const KaleidoscopeContext = lazy(() =>
    import('kaleidoscope-mfe').then(module => ({
      default: module.Context,
    })),
  );
  const ModelDetailsContext = lazy(() =>
    import('model-details-mfe').then(module => ({
      default: module.Context,
    })),
  );
  const ModelBehaviorContext = lazy(() =>
    import('model-behavior-mfe').then(module => ({
      default: module.Context,
    })),
  );
  const KPIDashboardContext = lazy(() =>
    import('kpi-dashboard-mfe').then(module => ({
      default: module.Context,
    })),
  );
  const FeatureHealthContext = lazy(() =>
    import('feature-health-mfe').then(module => ({
      default: module.Context,
    })),
  );
  const EventsManagementContext = lazy(() =>
    import('events-mgmt-mfe').then(module => ({
      default: module.Context,
    })),
  );
  const AlertLogsContext = lazy(() =>
    import('alert-logs-mfe').then(module => ({
      default: module.Context,
    })),
  );
  const LayoutPagesContext = lazy(() =>
    import('gen-layout-studio-mfe').then(module => ({
      default: module.Context,
    })),
  );
  const PlantsRoutesWithRouter = lazy(() =>
    import('plant-health-mfe').then(module => ({
      default: module.PlantsRoutesWithRouter,
    })),
  );
  const PlantHealthContext = lazy(() =>
    import('plant-health-mfe').then(module => ({
      default: module.Context,
    })),
  );
  const OptimizerContext = lazy(() =>
    import('optimizer-mfe').then(module => ({
      default: module.Context,
    })),
  );
  const RecordsAnalysisRoutesWithRouter = lazy(() =>
    import('records-analysis-mfe').then(module => ({
      default: module.RecordsAnalysisRoutesWithRouter,
    })),
  );
  const UserPreferences = lazy(() =>
    import('user-preferences-mfe').then(module => ({
      default: module.UserPreferences,
    })),
  );
  const EmailSubscriptions = lazy(() =>
    import('user-preferences-mfe').then(module => ({
      default: module.EmailSubscriptions,
    })),
  );
  const EventsManagement = lazy(() =>
    import('events-mgmt-mfe').then(module => ({
      default: module.EventsManagement,
    })),
  );
  const SystemDiagramWithRouter = lazy(() =>
    import('system-diagram-mfe').then(module => ({
      default: module.SystemDiagramWithRouter,
    })),
  );
  const KaleidoscopeWithRouter = lazy(() =>
    import('kaleidoscope-mfe').then(module => ({
      default: module.KaleidoscopeRoutesWithRouter,
    })),
  );
  const ModelDetailsPageRoutesWithRouter = lazy(() =>
    import('model-details-mfe').then(module => ({
      default: module.ModelDetailsPageRoutesWithRouter,
    })),
  );
  const ModelBehaviorPageRoutesWithRouter = lazy(() =>
    import('model-behavior-mfe').then(module => ({
      default: module.ModelBehaviorPageRoutesWithRouter,
    })),
  );
  const DashboardRoutesWithRouter = lazy(() =>
    import('kpi-dashboard-mfe').then(module => ({
      default: module.DashboardRoutesWithRouter,
    })),
  );
  const FeatureHealthWithRouter = lazy(() =>
    import('feature-health-mfe').then(module => ({
      default: module.FeatureHealthWithRouter,
    })),
  );
  const RiskTriageWithRouter = lazy(() =>
    import('alert-logs-mfe').then(module => ({
      default: module.RiskTriageWithRouter,
    })),
  );
  const LayoutExplorerPageRoutesWithRouter = lazy(() =>
    import('gen-layout-studio-mfe').then(module => ({
      default: module.LayoutExplorerPageRoutesWithRouter,
    })),
  );
  const LayoutStudioPageRoutesWithRouter = lazy(() =>
    import('gen-layout-studio-mfe').then(module => ({
      default: module.LayoutStudioPageRoutesWithRouter,
    })),
  );
  const Optimizer = lazy(() =>
    import('optimizer-mfe').then(module => ({
      default: module.Optimizer,
    })),
  );

  const {
    settings,
    setSettings,
    landingComponent,
    setLandingComponent,
    isInternalComponentSelected,
    setIsInternalComponentSelected,
  } = useAppNavRouting();
  const { nav, session } = useContext(StoreContext);
  const history = useHistory();
  const location = useLocation();
  const getSettings = () => {
    fetch('/api/settings')
      .then(res => res.json())
      .then((data: Settings) =>
        retraceAppNavRouting({
          HIERARCHY: APPS_HIERARCHY,
          history,
          data,
          setSettings,
          setLandingComponent,
        }),
      );
  };

  useSetupIntercom();
  useEffect(() => {
    getSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { firstName, lastName, email } = session;
  const name = getName(firstName, lastName, email);
  useEffect(() => {
    if (
      !(settings as Settings)?.appConfig ||
      (settings as Settings)?.appConfig?.intercom?.isDisabled
    ) {
      return;
    }
    if (firstName || lastName || email) {
      window.Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: INTERCOM_APP_ID,
        hide_default_launcher: false,
        alignment: 'right',
        horizontal_padding: 20,
        name: getName(firstName, lastName, email),
        email,
        created_at: new Date().getTime(),
      });
    }
  }, [firstName, lastName, email, settings]);

  const navbarMenuItems = useMemo(
    () => [
      {
        label: 'Switch Theme',
        icon: <BrightnessMediumRoundedIcon />,
        testId: 'menu-switch-theme',
        onClick: () => nav.toggleTheme(),
      },
      {
        label: 'Logout',
        icon: <LogoutRoundedIcon />,
        testId: 'menu-logout',
        onClick: () => session.dispatchLogout(),
      },
    ],
    [nav, session],
  );
  const reliabilityMenuItems = useReliabilitySettingsItems();
  const monitorMenuItems = useMonitorSettingsItems();

  useEffect(() => {
    const [_, appId, componentId] = location.pathname.split('/');
    const internalRoute = APPS_HIERARCHY.find(
      item => item.id === appId,
    )?.modules.find(item => item.id === componentId && item?.isInternal);
    setIsInternalComponentSelected(internalRoute ? true : false);
    window.Intercom('update');
  }, [location.pathname, setIsInternalComponentSelected]);

  return (
    <AppNavContext>
      <AutoLogout />
      {!settings?.appNav ? (
        <AppActivity type="blocking" />
      ) : (
        <AppNav
          componentID={location.pathname.split('/')?.[2]}
          apps={processMenu(APPS_HIERARCHY, [
            {
              id: 'monitor',
              sidebarSettings: {
                target: 'Settings',
                content: <NavbarMenu items={monitorMenuItems} />,
              },
            },
            {
              id: 'reliability',
              sidebarSettings: {
                target: 'Settings',
                content: <NavbarMenu items={reliabilityMenuItems} />,
              },
            },
          ])}
          navbarSettings={{
            userName: name,
            content: <NavbarMenu items={navbarMenuItems} />,
          }}
          config={settings?.appNav}
          onAction={{
            click: ({ app, component }) => {
              if (!hasId(app) && !hasId(component)) {
                history.push('/access-denied');
                return;
              }
              history.push(`/${app?.id}/${component?.id}`);
            },
          }}
          isInternalComponentSelected={isInternalComponentSelected}>
          <ErrorBoundary fallback={ErrorFallback}>
            <Switch>
              <Route path="/health/plant-health*">
                <Suspense fallback={<Activity type="blocking" />}>
                  <PlantHealthContext
                    theme={theme}
                    logger={prefixConsole('plant-health-mfe:')}
                    api={phFetch}
                    storage={prefixStorage(
                      `${domainName}.plant-health-mfe.`,
                      sessionStorage,
                    )}
                    components={appComponents}>
                    <AppGlobals />
                    <PlantsRoutesWithRouter basename="/health/plant-health/" />
                  </PlantHealthContext>
                </Suspense>
              </Route>
              <Route path="/health/records-analysis*">
                <Suspense fallback={<Activity type="blocking" />}>
                  <RecordsAnalysisContext
                    theme={theme}
                    logger={prefixConsole('records-analysis-mfe:')}
                    api={raFetch}
                    storage={prefixStorage(
                      `${domainName}.records-analysis-mfe.`,
                      sessionStorage,
                    )}
                    components={appComponents}>
                    <AppGlobals />
                    <RecordsAnalysisRoutesWithRouter basename="/health/records-analysis/" />
                  </RecordsAnalysisContext>
                </Suspense>
              </Route>
              <Route path="/monitor/layout-studio*">
                <Suspense fallback={<Activity type="blocking" />}>
                  <LayoutPagesContext
                    theme={theme}
                    logger={prefixConsole('gen-layout-studio-mfe:')}
                    api={layoutFetch}
                    storage={prefixStorage(
                      `${domainName}.gen-layout-studio-mfe.`,
                      sessionStorage,
                    )}
                    components={{ ...appComponents }}>
                    <AppGlobals />
                    <LayoutStudioPageRoutesWithRouter
                      basename="/monitor/layout-studio/"
                      customerName={
                        (settings as Settings)?.appConfig?.layout
                          ?.customerName as LayoutConfig['customerName']
                      }
                    />
                  </LayoutPagesContext>
                </Suspense>
              </Route>
              <Route path="/monitor/dashboard-gallery*">
                <Suspense fallback={<Activity type="blocking" />}>
                  <LayoutPagesContext
                    theme={theme}
                    logger={prefixConsole('gen-layout-studio-mfe:')}
                    api={layoutFetch}
                    storage={prefixStorage(
                      `${domainName}.gen-layout-studio-mfe.`,
                      sessionStorage,
                    )}
                    components={{ ...appComponents }}>
                    <AppGlobals />
                    <LayoutExplorerPageRoutesWithRouter
                      headerName="Dashboard Gallery"
                      basename="/monitor/dashboard-gallery/"
                    />
                  </LayoutPagesContext>
                </Suspense>
              </Route>
              <Route path="/reliability/model-behavior*">
                <Suspense fallback={<Activity type="blocking" />}>
                  <ModelBehaviorContext
                    theme={theme}
                    api={mbFetch}
                    components={{
                      Modal,
                      useNotifications,
                      AppDrawer,
                    }}>
                    <AppGlobals />
                    <ModelBehaviorPageRoutesWithRouter
                      options={
                        (settings as Settings)?.appConfig
                          ?.modelBehavior as ModelBehaviorConfig
                      }
                      email={email as string}
                      basename="/reliability/model-behavior"
                      headerText="Model Overview"
                      onNavigate={location => {
                        history.push(location.pathname);
                      }}
                    />
                  </ModelBehaviorContext>
                </Suspense>
              </Route>
              <Route path="/reliability/model-details*">
                <Suspense fallback={<Activity type="blocking" />}>
                  <ModelDetailsContext
                    theme={theme}
                    api={modelDetailsFetch}
                    storage={prefixStorage(`${domainName}.model-details-mfe.`)}
                    components={{
                      Modal,
                      AppDrawer,
                    }}>
                    <AppGlobals />
                    <ModelDetailsPageRoutesWithRouter
                      UIOptions={
                        (settings as Settings)?.appConfig
                          ?.modelDetails as ModelDetailsConfig
                      }
                      userInfo={{ email }}
                      basename="/reliability/model-details/"
                      onNavigate={location => {
                        history.push(location.pathname);
                      }}
                    />
                  </ModelDetailsContext>
                </Suspense>
              </Route>
              <Route path="/reliability/events">
                <Suspense fallback={<Activity type="blocking" />}>
                  <EventsManagementContext
                    theme={theme}
                    api={eventsFetch}
                    storage={prefixStorage('')}
                    components={{
                      Modal,
                      useNotifications,
                    }}>
                    <AppGlobals />
                    <EventsManagement />
                  </EventsManagementContext>
                </Suspense>
              </Route>
              <Route path="/reliability/user-preferences*">
                <Suspense fallback={<Activity type="blocking" />}>
                  <UserPreferencesContext
                    theme={theme}
                    api={userPrefFetch}
                    storage={prefixStorage('user_preferences')}
                    components={{ Modal }}>
                    <AppGlobals />
                    <UserPreferences userEmail={email} />
                  </UserPreferencesContext>
                </Suspense>
              </Route>
              <Route path="/reliability/email-subscriptions*">
                <Suspense fallback={<Activity type="blocking" />}>
                  <UserPreferencesContext
                    theme={theme}
                    api={userPrefFetch}
                    storage={prefixStorage('user_preferences')}
                    components={{ Modal }}>
                    <AppGlobals />
                    <EmailSubscriptions />
                  </UserPreferencesContext>
                </Suspense>
              </Route>
              <Route path="/reliability/alert-logs*">
                <Suspense fallback={<Activity type="blocking" />}>
                  <AlertLogsContext
                    theme={theme}
                    api={alFetch}
                    components={{
                      Modal,
                      AppDrawer,
                    }}>
                    <AppGlobals />
                    <RiskTriageWithRouter
                      customer={
                        (settings as Settings)?.appConfig?.alertLogs
                          ?.customerName
                      }
                      alertUIOptions={
                        (settings as Settings)?.appConfig?.alertLogs
                          ?.alertUIOptions
                      }
                      urlConfig={
                        (settings as Settings)?.appConfig?.alertLogs?.urlConfig
                      }
                      userEmail={email}
                      basename="/reliability/alert-logs/"
                      onNavigate={(location: Location) => {
                        history.push(location.pathname);
                      }}
                    />
                  </AlertLogsContext>
                </Suspense>
              </Route>
              <Route path="/reliability/feature-health*">
                <Suspense fallback={<Activity type="blocking" />}>
                  <FeatureHealthContext
                    theme={theme}
                    api={fhFetch}
                    logger={prefixConsole('feature-health:')}>
                    <AppGlobals />
                    <FeatureHealthWithRouter
                      email={email}
                      basename="/reliability/feature-health/"
                      testId="featurehealth"
                      urlConfig={
                        (settings as Settings)?.appConfig?.featureHealth
                          ?.urlConfig
                      }
                      onNavigate={(location: Location) => {
                        history.push(location.pathname);
                      }}
                    />
                  </FeatureHealthContext>
                </Suspense>
              </Route>
              <Route path="/monitor/data-dashboard*">
                <Suspense fallback={<Activity type="blocking" />}>
                  <KPIDashboardContext
                    theme={theme}
                    logger={prefixConsole('kpi-dashboard-mfe:')}
                    api={kdFetch}
                    storage={prefixStorage(
                      `${domainName}.kpi-dashboard-mfe.`,
                      sessionStorage,
                    )}
                    components={{ ...appComponents, AppDrawer }}>
                    <AppGlobals />
                    <DashboardRoutesWithRouter
                      basename="/monitor/data-dashboard/"
                      customerName={
                        (settings as Settings)?.appConfig?.dashboard
                          ?.customerLayout as DashboardConfig['customerLayout']
                      }
                    />
                  </KPIDashboardContext>
                </Suspense>
              </Route>
              <Route path="/system/system-diagram*">
                <Suspense fallback={<Activity type="blocking" />}>
                  <SystemDiagramContext
                    theme={theme}
                    logger={prefixConsole('system-diagram-mfe:')}
                    api={sdFetch}
                    storage={prefixStorage(
                      `${domainName}.system-diagram-mfe.`,
                      sessionStorage,
                    )}
                    components={appComponents}>
                    <AppGlobals />
                    <SystemDiagramWithRouter
                      basename="/system/system-diagram/"
                      customerName={
                        (settings as Settings)?.appConfig?.systemDiagram
                          ?.customerName as SystemDiagramConfig['customerName']
                      }
                    />
                  </SystemDiagramContext>
                </Suspense>
              </Route>
              <Route path="/llm-assistant/ai-dialogue*">
                <Suspense fallback={<Activity type="blocking" />}>
                  <KaleidoscopeContext
                    theme={theme}
                    logger={prefixConsole('kaleidoscope-mfe:')}
                    api={kalFetch}
                    storage={prefixStorage(
                      `${domainName}.kaleidoscope-mfe.`,
                      sessionStorage,
                    )}
                    components={appComponents}>
                    <AppGlobals />
                    <KaleidoscopeWithRouter
                      basename="/llm-assistant/ai-dialogue/"
                      onNavigate={location => {
                        history.push(location.pathname);
                      }}
                    />
                  </KaleidoscopeContext>
                </Suspense>
              </Route>
              <Route path="/planning/optimizer*">
                <Suspense fallback={<Activity type="blocking" />}>
                  <OptimizerContext
                    theme={theme}
                    logger={prefixConsole('optimizer-mfe:')}
                    storage={prefixStorage(
                      `${domainName}.optimizer-mfe.`,
                      sessionStorage,
                    )}
                    components={appComponents}>
                    <AppGlobals />
                    <Optimizer
                      baseImageUrl="https://playground.p4d.sparkcognition.dev/sites/assets-viewer-mfe/plant.png"
                      actualData={actualData}
                      projectedData={projectedData}
                      assetDimensionsList={assetDimensionsList}
                      popOverConfiguration={MOCK_REF_VALUE}
                    />
                  </OptimizerContext>
                </Suspense>
              </Route>
              <Route
                path="/access-denied"
                render={() => (
                  <AccessDenied
                    onAction={{
                      click: () => {
                        setSettings(prevSettings => ({
                          appNav: {
                            ...(prevSettings?.appNav as Partial<AppNavConfig> & {
                              portalURL: string;
                            }),
                            homePage: landingComponent?.appId,
                          },
                          appConfig: (settings as Settings)?.appConfig,
                        }));
                        history.push(
                          `/${landingComponent?.appId}/${landingComponent?.componentId}`,
                        );
                      },
                    }}
                  />
                )}
              />
              <Route exact path={['/', '/login/']}>
                <Redirect from="/" to={`/${landingComponent?.appId}`} />
              </Route>
              <Route
                path="*"
                render={() => (
                  <NotFound
                    onAction={{
                      click: () => {
                        setSettings(prevSettings => ({
                          appNav: {
                            ...(prevSettings?.appNav as Partial<AppNavConfig> & {
                              portalURL: string;
                            }),
                            homePage: landingComponent?.appId,
                          },
                          appConfig: (settings as Settings)?.appConfig,
                        }));
                        history.push(
                          `/${landingComponent?.appId}/${landingComponent?.componentId}`,
                        );
                      },
                    }}
                  />
                )}
              />
            </Switch>
          </ErrorBoundary>
        </AppNav>
      )}
    </AppNavContext>
  );
});
