// SparkCognition Proprietary and Confidential.
// ©SparkCognition 2022. All Rights Reserved.
import { Modal } from '@core-ui/components';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { fulfilledValue } from '../../lib/mobx/observablePromise';
import { StoreContext } from '../../store';

const WARNING_TIME = 1000 * 60 * 60 * 4; // 4 hours
const LOGOUT_TIME = 1000 * 60 * 5; // 5 minutes

interface AutoLogoutState {
  warningTimer?: ReturnType<typeof setTimeout>;
  countdownTimer?: ReturnType<typeof setTimeout>;
  countdownText?: string;
}

export const AutoLogout: React.FC = observer(() => {
  const { session, override } = useContext(StoreContext);
  const [state, setState] = useState<AutoLogoutState>({});
  const routerState = useLocation();
  const showModal = Boolean(state.countdownText);
  const idToken = fulfilledValue(session.idToken.current());
  const hasIdToken = Boolean(idToken);

  // Start the warning timer, which when fired will start the countdown interval.
  const startTimers = () => {
    // Determine timer lengths.
    const warningTime = override.overrideTime(
      'autologoutWarningTime',
      WARNING_TIME,
    );
    const logoutTime = override.overrideTime(
      'autologoutLogoutTime',
      LOGOUT_TIME,
    );
    console.log(
      `reseting AutoLogout timers: warningTime=${warningTime}, logoutTime=${logoutTime}`,
    );

    // Make sure we start with clean timers.
    if (state.warningTimer) clearTimeout(state.warningTimer);
    if (state.countdownTimer) clearTimeout(state.countdownTimer);

    // This is a recursive function to start a self-correcting timer to countdown
    // each second. The self-correction is needed to give the component the best
    // chance to show a smooth rendering timer. An interval uses a static latency,
    // which often lands at the wrong time, and results in the countdown skipping seconds.
    const tick = (startTime: number) => {
      const countdownLeft = logoutTime - Date.now() + startTime;
      if (countdownLeft < 0) {
        // no time left... logout
        console.warn('logging out due to timeout');
        session.dispatchLogout();
      } else {
        // update the clock
        const minutes = Math.floor(countdownLeft / 60000);
        const seconds = Math.floor(countdownLeft / 1000) % 60;
        const countdownText = `${minutes < 10 ? '0' : ''}${minutes} : ${
          seconds < 10 ? '0' : ''
        }${seconds}`;
        // tell it to fire at the top of the next second
        const nowTick = Date.now() % 1000;
        const timeToNextTick = 1000 - nowTick;
        const countdownTimer = setTimeout(
          () => tick(startTime),
          timeToNextTick,
        );
        setState({ countdownTimer, countdownText });
      }
    };

    // Start the timer to present the modal.
    const warningTimer = setTimeout(() => {
      const startTime = Date.now();
      tick(startTime);
    }, warningTime);

    // Update the state and reset the interval pieces, as this timer may have just restarted.
    setState({ warningTimer });
  };

  // After render, determine timer state.
  // Restart the timer if router state changes (which denotes activity on the system).
  useEffect(
    () => {
      if (hasIdToken) {
        startTimers();
      }
    },
    // TODO: useCallback or useRef for startTimers
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasIdToken, routerState],
  );

  return (
    <Modal
      priority="security"
      isVisible={showModal}
      label="Stay logged in?"
      actionButton={{ label: 'Stay Logged In' }}
      dismissButton={{ label: 'Logout' }}
      onAction={{
        action: () => startTimers(),
        dismiss: () => session.dispatchLogout(),
      }}
      testId="autologout-modal"
      content={
        <h4>
          You have been inactive for a while...
          <br />
          For security purposes, this session will be logged out in &nbsp;
          <span data-test="autologout-time-countdown">
            {state.countdownText}
          </span>
          .
        </h4>
      }
    />
  );
});
