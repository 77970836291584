// SparkCognition Proprietary and Confidential.
// ©SparkCognition 2023. All Rights Reserved.
// import { AssetDimensions } from 'assets-viewer-mfe';
// TODO: Remove this folder once optimizer-mfe is updated

// Austin CSV Data
export const actualData = {
  activePower: 396,
  noxEmmission: 147,
  fuelGas: 147,
  ammonia: 147,
  totalFuelFlow: 133,
  totalAirFlow: 1273,
  fuleGasTemp: 168.2,
  fuleGaso2: 168.2,
};

// Dallas CSV Data
export const projectedData = {
  activePower: 593,
  noxEmmission: 122,
  fuelGas: 122,
  ammonia: 122,
  totalFuelFlow: 178,
  totalAirFlow: 1782,
  fuleGasTemp: 176.6,
  fuleGaso2: 176.6,
};

// Asset Data from asset-viewer-mfe
export const assetDimensionsList: any[] = [
  {
    id: '6cd49daf-8dab-42b2-8720-79e2c4d65f19',
    type: 'polygon',
    tooltipProps: [
      {
        type: 'smartForm',
        ref: 'SMART_FORM',
        metadata: {
          data: {
            id: '',
            name: '',
            formLayout: {
              rows: [
                {
                  id: 'd710fea2-67ba-4702-b397-291687a5db04',
                  gap: 0,
                  columns: [
                    {
                      fieldId: '48d99c63-85a9-478f-8ec8-97652cikja8507',
                      dataType: 'label',
                      fieldLabel: 'Active Power',
                      direction: 'input',
                      layout: {
                        xs: 3,
                        sm: 3,
                        md: 4,
                        lg: 4,
                        align: 'start',
                      },
                    },
                    {
                      fieldId: '00d3cccb-8c59-4515-b8df-302a319f94ef',
                      dataType: 'label',
                      fieldLabel: 'NOx Emissions',
                      direction: 'input',
                      layout: {
                        xs: 3,
                        sm: 3,
                        md: 4,
                        lg: 4,
                        align: 'start',
                      },
                    },
                  ],
                },
              ],
            },
          },
          defaultValues: [
            {
              fieldId: '48d99c63-85a9-478f-8ec8-97652cikja8507',
              value: '593 Mw',
            },
            {
              fieldId: '00d3cccb-8c59-4515-b8df-302a319f94ef',
              value: '147 (Ng/Nm3)',
            },
          ],
        },
      },
      {
        type: 'chart',
        ref: 'DOWNTIME_GRAPH_ASSET1',
      },
    ],
    polygonProps: {
      positions: [
        [30, 340.203125],
        [27, 337.203125],
        [27, 249.203125],
        [30, 246.203125],
        [90, 246.203125],
        [93, 249.203125],
        [93, 337.203125],
        [90, 340.203125],
      ],
    },
  },
  {
    id: 'd92bc69b-6c66-432c-9997-738b2031658c',
    type: 'polygon',
    tooltipProps: [
      {
        ref: 'SMART_FORM_2',
        type: 'smartForm',
        metadata: {
          data: {
            id: '',
            name: '',
            formLayout: {
              rows: [
                {
                  id: 'ca13c431-dd94-40ca-937e-5ae08fe9ea7c',
                  gap: 4,
                  columns: [
                    {
                      fieldId: '1394dc6f-0a6e-4806-bc3d-614b74e1beb1',
                      dataType: 'label',
                      fieldLabel: 'Active Power',
                      direction: 'input',
                      layout: {
                        xs: 3,
                        sm: 3,
                        md: 4,
                        lg: 4,
                        align: 'start',
                      },
                    },
                    {
                      fieldId: 'a0bcf836-d16d-4ef4-ba76-a2f777e86008',
                      dataType: 'label',
                      fieldLabel: 'NOx Emissions',
                      direction: 'input',
                      layout: {
                        xs: 3,
                        sm: 3,
                        md: 4,
                        lg: 4,
                        align: 'start',
                      },
                    },
                  ],
                },
              ],
            },
          },
          defaultValues: [
            {
              fieldId: '1394dc6f-0a6e-4806-bc3d-614b74e1beb1',
              value: '600 Mw',
            },
            {
              fieldId: 'a0bcf836-d16d-4ef4-ba76-a2f777e86008',
              value: '155 (Ng/Nm3)',
            },
          ],
        },
      },
      {
        type: 'chart',
        ref: 'DOWNTIME_GRAPH_ASSET2',
      },
    ],
    polygonProps: {
      style: {
        color: 'red',
      },
      positions: [
        [168, 632.203125],
        [165, 629.203125],
        [165, 540.203125],
        [168, 537.203125],
        [228, 537.203125],
        [231, 540.203125],
        [231, 629.203125],
        [228, 632.203125],
      ],
    },
  },
];

// Todo: Split smartform configuration and default value separately
export const MOCK_REF_VALUE = {
  SMART_FORM: {
    data: {
      id: '',
      name: '',
      formLayout: {
        rows: [
          {
            id: 'd710fea2-67ba-4702-b397-291687a5db04',
            gap: 0,
            columns: [
              {
                fieldId: '48d99c63-85a9-478f-8ec8-97652c1a8507',
                dataType: 'label',
                fieldLabel: 'Emissions',
                direction: 'input',
                layout: {
                  xs: 6,
                  sm: 6,
                  md: 4,
                  lg: 4,
                  align: 'start',
                },
              },
              {
                fieldId: '00d3cccb-8c59-4515-b8df-302a319f94ef',
                dataType: 'label',
                fieldLabel: 'Cost',
                direction: 'input',
                layout: {
                  xs: 6,
                  sm: 6,
                  md: 4,
                  lg: 4,
                  align: 'start',
                },
              },
            ],
          },
        ],
      },
    },
    defaultValues: [
      {
        fieldId: '48d99c63-85a9-478f-8ec8-97652c1a8507',
        value: '',
      },
      {
        fieldId: '00d3cccb-8c59-4515-b8df-302a319f94ef',
        value: '80000',
      },
    ],
  },
  SMART_FORM_2: {
    data: {
      id: '',
      name: '',
      formLayout: {
        rows: [
          {
            id: 'ca13c431-dd94-40ca-937e-5ae08fe9ea7c',
            gap: 4,
            columns: [
              {
                fieldId: '48df7f55-c401-4554-9682-7bed22ceb876',
                dataType: 'label',
                fieldLabel: 'Utilization',
                direction: 'input',
                layout: {
                  xs: 4,
                  sm: 4,
                  md: 4,
                  lg: 4,
                  align: 'start',
                },
              },
              {
                fieldId: '1394dc6f-0a6e-4806-bc3d-614b74e1beb1',
                dataType: 'label',
                fieldLabel: 'Cost',
                direction: 'input',
                layout: {
                  xs: 4,
                  sm: 4,
                  md: 4,
                  lg: 4,
                  align: 'start',
                },
              },
              {
                fieldId: 'a0bcf836-d16d-4ef4-ba76-a2f777e86008',
                dataType: 'label',
                fieldLabel: 'Dummy Label 2',
                direction: 'input',
                layout: {
                  xs: 4,
                  sm: 4,
                  md: 4,
                  lg: 4,
                  align: 'start',
                },
              },
              {
                fieldId: 'a0bcf836-d16d-4ef4-ba76-a2f777e86008',
                dataType: 'label',
                fieldLabel: 'Dummy Label 3',
                direction: 'input',
                layout: {
                  xs: 4,
                  sm: 4,
                  md: 4,
                  lg: 4,
                  align: 'start',
                },
              },
            ],
          },
        ],
      },
    },
    defaultValues: [
      {
        fieldId: '48df7f55-c401-4554-9682-7bed22ceb876',
        value: '8/5/2022',
      },
      {
        fieldId: '1394dc6f-0a6e-4806-bc3d-614b74e1beb1',
        value: 'Dummy Value 1',
      },
      {
        fieldId: 'a0bcf836-d16d-4ef4-ba76-a2f777e86008',
        value: 'Dummy Value 2',
      },
      {
        fieldId: 'a0bcf836-d16d-4ef4-ba76-a2f777e86008',
        value: 'Dummy Value 3',
      },
    ],
  },
  DOWNTIME_GRAPH_ASSET1: {
    chart: {
      height: 120,
    },
    title: '',
    xAxis: {
      categories: [
        'V0',
        'V1',
        'q0',
        'q1',
        'q2',
        'q3',
        'q4',
        'q5',
        'q6',
        'q7',
        'q8',
        'cost',
        'constraint',
      ],
      labels: {
        enabled: false,
      },
    },
    yAxis: {
      title: {
        text: 'Value',
      },
    },
    series: [
      {
        name: 'NOx Emissions (mg/Nm3)',
        data: [
          2.19, 7.36, 95.12, 431.42, 198.38, 58.89, 97.1, 92.31, 74.95, 69.64,
          47.71, 32.9, -0.02,
        ],
        color: '#5252C1',
      },
      {
        name: 'Active Power (Mw)',
        data: [
          2.19, 60.36, 100.15, 175.5, 190.56, 200, 222.5, 284.7, 300, 365.1,
          470.5,
        ],
        color: '#E25B5B',
      },
    ],
    legend: {
      enabled: false,
    },
  },
  DOWNTIME_GRAPH_ASSET2: {
    chart: {
      height: 120,
    },
    title: '',
    xAxis: {
      categories: ['V0', 'V1', 'q0', 'q1', 'q2', 'q3', 'q4', 'q5'],
      labels: {
        enabled: false,
      },
    },
    yAxis: {
      title: {
        text: 'Value',
      },
    },
    series: [
      {
        name: 'NOx Emissions (mg/Nm3)',
        data: [2.19, 7.36, 91.12, 43.42, 19.38, 58.89, 100, 120],
        color: '#5252C1',
      },
      {
        name: 'Active Power (Mw)',
        data: [82.19, 90.36, 150.15, 205.5, 260.56, 290, 302.5, 400.9],
        color: '#E25B5B',
      },
    ],
    legend: {
      enabled: false,
    },
  },
};
