// SparkCognition Proprietary and Confidential.
// ©SparkCognition 2022-2024. All Rights Reserved.
import { createRoot } from 'react-dom/client';
import { configure } from 'mobx';
import { enableLogging } from 'mobx-logger';
import { App } from './app/App';
import { Contexts } from './app/Contexts';

// Configure MobX
configure({ enforceActions: 'observed' });
if (process.env.NODE_ENV !== 'production') {
  enableLogging({
    action: false,
    reaction: false,
    transaction: false,
    compute: false,
  });
}
const node = document.getElementById('root') as Element;
const root = createRoot(node);
root.render(
  <Contexts>
    <App />
  </Contexts>,
);
