// SparkCognition Proprietary and Confidential.
// ©SparkCognition 2023. All Rights Reserved.
import {
  dispatchToMap,
  Flex,
  Illustration,
  Link,
  StatusOverlay,
  Theme,
  UniversalActionHandler,
} from '@core-ui/components';

export const AccessDenied = ({
  onAction,
}: {
  onAction: UniversalActionHandler<{
    action: 'click';
  }>;
}) => {
  return (
    <Flex
      justify="center"
      align="center"
      css={(theme: Theme) => ({
        height: '100%',
        width: '100%',
        backgroundColor: theme.neutral.v('+6', '-5'),
      })}>
      <StatusOverlay
        icon={<Illustration.LockedDocument />}
        label={
          <Flex>
            <Flex> Access Denied</Flex>
            <Flex
              css={(theme: Theme) => ({
                color: theme.neutral.v('+2', '+2'),
                marginTop: 8,
                fontWeight: 'normal',
                marginBottom: 8,
              })}>
              You don&apos;t have access to this page
            </Flex>
            <Flex align="center">
              <Link
                label="Go Back to Main Page"
                onAction={{
                  click: () =>
                    dispatchToMap(onAction)({
                      action: 'click',
                    }),
                }}
              />
            </Flex>
          </Flex>
        }
      />
    </Flex>
  );
};
