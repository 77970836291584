// SparkCognition Proprietary and Confidential.
// ©SparkCognition 2022-2023. All Rights Reserved.
import {
  dispatchToMap,
  Flex,
  Illustration,
  Link,
  StatusOverlay,
  UniversalActionHandler,
} from '@core-ui/components';
import { Theme } from '@emotion/react';

export const NotFound = ({
  onAction,
}: {
  onAction: UniversalActionHandler<{
    action: 'click';
  }>;
}) => (
  <Flex
    justify="center"
    align="center"
    css={(theme: Theme) => ({
      height: '100%',
      width: '100%',
      backgroundColor: theme.neutral.v('+6', '-5'),
    })}>
    <StatusOverlay
      icon={<Illustration.NotFound />}
      label="Page Not Found"
      description="We’re sorry, we can’t find the page you’re looking for"
      action={
        <Link
          label="Go Back to Main Page"
          onAction={{
            click: () =>
              dispatchToMap(onAction)({
                action: 'click',
              }),
          }}
        />
      }
    />
  </Flex>
);
