/* eslint-disable camelcase */
// SparkCognition Proprietary and Confidential.
// ©SparkCognition 2022-2023. All Rights Reserved.
import { RiskTriageProps } from 'alert-logs-mfe';
import { AppSettings } from 'app-navigation-ui';
import { ModelBehaviorConfig } from 'model-behavior-mfe';
import { ModelDetailsConfig } from 'model-details-mfe';
import { has } from 'ramda';

export type SystemDiagramConfig = {
  customerName: string;
};

export type DashboardConfig = {
  customerLayout: string;
};

export type LayoutConfig = {
  apiKey: string;
  customerName: string;
};

export type FeatureHealthConfig = {
  customerName: string;
  urlConfig: {
    landing: string;
    modelsStatePage: string[];
  };
};

export type IntercomConfig = {
  isDisabled: boolean;
};

export type SettingsAppConfig = {
  systemDiagram?: SystemDiagramConfig;
  layout?: LayoutConfig;
  dashboard?: DashboardConfig;
  featureHealth?: FeatureHealthConfig;
  modelBehavior?: ModelBehaviorConfig;
  modelDetails?: ModelDetailsConfig;
  alertLogs?: {
    customerName: Pick<RiskTriageProps, 'customer'>;
    alertUIOptions: Pick<RiskTriageProps, 'alertUIOptions'>;
    urlConfig: Pick<RiskTriageProps, 'urlConfig'>;
  };
  intercom?: IntercomConfig;
};

export const hasId = has('id');

export type Settings = Pick<AppSettings, 'appNav'> & {
  appConfig: SettingsAppConfig;
};

export const getName = (
  firstName?: string,
  lastName?: string,
  email?: string,
) =>
  firstName || lastName
    ? `${firstName ?? ''} ${lastName ?? ''}`.trim()
    : `${email ?? ''}`;
