// SparkCognition Proprietary and Confidential.
// ©SparkCognition 2022. All Rights Reserved.
import React from 'react';

import { NavStore } from './NavStore';
import { OverrideStore } from './OverrideStore';
import { SessionStore } from './SessionStore';

// This was in settings.js, but when that moved to `config`,
// we lost the ability to pull in because `config` is server-side only.
// Hard-code for now.
export const domainName = 'SparkCognition.ManufacturingSuite';

/**
 * This is the main Store for the app.
 */
export class Store {
  nav: NavStore;
  override: OverrideStore;
  session: SessionStore;

  constructor() {
    this.nav = new NavStore(domainName);
    this.override = new OverrideStore(domainName);
    this.session = new SessionStore(this.override);
  }
}

export const StoreContext = React.createContext({} as Store);
