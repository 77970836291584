// SparkCognition Proprietary and Confidential.
// ©SparkCognition 2022-2024. All Rights Reserved.
/// <reference types="@emotion/react/types/css-prop" />
// The above line fixes a type issue with css on behalf of emotion.
// Do not remove.

import {
  CUISSessionManager,
  Context as UserContext,
  prefixConsole,
} from '@core-ui/client';
import {
  addCSRFHeader,
  intercept,
  mapUrl,
  urlMatches,
  when,
} from '@core-ui/client/dist/fetch';
import {
  Alert,
  AppActivity,
  Context as CoreUIContext,
  Modal,
  useNotifications,
} from '@core-ui/components';
import { defaultTheme } from '@core-ui/theme';
import { observer } from 'mobx-react';
import React, { PropsWithChildren, Suspense, useMemo } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { prefixStorage } from '../lib/storage';
import { Store, StoreContext, domainName } from '../store';

const store = new Store();
const userContextLogger = prefixConsole('user:');
export const userContextFetch = intercept(fetch, [addCSRFHeader()]);
const userSessionManager = new CUISSessionManager({ api: userContextFetch });
const userStorage = prefixStorage(`${domainName}.user.`, sessionStorage);
export const mfeFetch = [
  when(
    urlMatches(/\/sp/),
    mapUrl(url => url.replace('/sp', '/ms/sp')),
  ),
  when(
    urlMatches(/\/kong/),
    mapUrl(url => url.replace('/kong', '/ms/kong')),
  ),
  when(
    urlMatches(/\/hermes/),
    mapUrl(url => url.replace('/hermes', '/ms/hermes')),
  ),
  when(
    urlMatches(/\/fhs/),
    mapUrl(url => url.replace('/fhs', '/ms/fhs')),
  ),
  when(
    urlMatches(/\/hs/),
    mapUrl(url => url.replace('/hs', '/ms/hs')),
  ),
];
export const modelOpsFetch: typeof fetch = intercept(fetch, [
  addCSRFHeader(),
  ...mfeFetch,
  when(
    urlMatches(/\/model-deployment-service/),
    mapUrl(url => `/mfe/kong${url}`),
  ),
  when(
    urlMatches(/\/model-data-service/),
    mapUrl(url => `/mfe/kong${url}`),
  ),
]);
export const userPrefFetch = modelOpsFetch;
export const fhFetch = intercept(fetch, [
  addCSRFHeader(),
  mapUrl(url => `/mfe/feature-health-mfe${url}`),
  ...mfeFetch,
]);

export const mbFetch = intercept(fetch, [
  addCSRFHeader(),
  when(
    urlMatches(/\/model-deployment-service/),
    mapUrl(url => `/kong${url}`),
  ),
  mapUrl(url => `/mfe/model-behavior-mfe${url}`),
  ...mfeFetch,
]);

export const modelDetailsFetch = intercept(fetch, [
  addCSRFHeader(),
  when(
    urlMatches(/\/model-data-service/),
    mapUrl(url => `/kong${url}`),
  ),
  mapUrl(url => `/mfe/model-details-mfe${url}`),
  ...mfeFetch,
]);

export const alFetch = intercept(fetch, [
  addCSRFHeader(),
  mapUrl(url => `/mfe/alert-logs-mfe${url}`),
  ...mfeFetch,
]);
export const phFetch: typeof fetch = intercept(fetch, [
  addCSRFHeader(),
  when(
    urlMatches(/\/ph/),
    mapUrl(url => url.replace('/ph', '/ms/ph')),
  ),
]);
export const eventsFetch: typeof fetch = intercept(fetch, [
  addCSRFHeader(),
  when(
    urlMatches(/\/em/),
    mapUrl(url => url.replace('/em', '/ms/em')),
  ),
]);

export const raFetch: typeof fetch = intercept(fetch, [
  addCSRFHeader(),
  when(
    urlMatches(/\/ra/),
    mapUrl(url => url.replace('/ra', '/ms/ra')),
  ),
]);
export const sdFetch: typeof fetch = intercept(fetch, [
  addCSRFHeader(),
  when(
    urlMatches(/\/sd/),
    mapUrl(url => url.replace('/sd', '/ms/sd')),
  ),
]);
export const kalFetch: typeof fetch = intercept(fetch, [
  addCSRFHeader(),
  when(
    urlMatches(/\/ld/),
    mapUrl(url => url.replace('/ld', '/ms/ld')),
  ),
]);
export const kdFetch: typeof fetch = intercept(fetch, [
  addCSRFHeader(),
  when(
    urlMatches(/\/kd/),
    mapUrl(url => url.replace('/kd', '/ms/kd')),
  ),
]);
export const layoutFetch: typeof fetch = intercept(fetch, [
  addCSRFHeader(),
  when(
    urlMatches(/\/dds/),
    mapUrl(url => url.replace('/dds', '/ms/layout')),
  ),
  when(
    urlMatches(/\/hs/),
    mapUrl(url => url.replace('/hs', '/ms/hs')),
  ),
]);

export const appComponents = {
  Activity: AppActivity,
  Alert,
  Modal,
  useNotifications,
};

export const Contexts = observer<React.FC<PropsWithChildren>>(
  ({ children }) => {
    const { themeVariant } = store.nav;
    const theme = useMemo(
      () => ({
        ...defaultTheme,
        variant: themeVariant,
        resources: '/assets',
      }),
      [themeVariant],
    );

    return useMemo(
      () =>
        compose(
          <Suspense fallback={null} />,
          <StoreContext.Provider value={store} />,
          <CoreUIContext theme={theme} cacheId="industrial-ai-suite-app" />,
          <Router />,
          <UserContext
            components={appComponents}
            logger={userContextLogger}
            sessionManager={userSessionManager}
            storage={userStorage}
            theme={theme}
          />,
          <>{children}</>,
        ),
      [theme, children],
    );
  },
);

// Functional composition for nested Contexts
const compose = (...components: React.ReactElement[]) =>
  components
    .reverse()
    .reduce((result, next) => React.cloneElement(next, [], result));
