// SparkCognition Proprietary and Confidential.
// ©SparkCognition 2022-2023. All Rights Reserved.
export const prefixStorage = (
  prefix: string = '',
  storage: Storage = localStorage,
): Storage =>
  new Proxy(storage, {
    get(target, prop: string) {
      switch (prop) {
        case 'getItem':
        case 'setItem':
        case 'removeItem':
          return (key: string, value?: string) =>
            target[prop](`${prefix}${key}`, value as any);
        default:
          return target[prop];
      }
    },
  });
