// SparkCognition Proprietary and Confidential.
// ©SparkCognition 2022-2023. All Rights Reserved.
import { Flex } from '@core-ui/components';
import { css, Theme } from '@emotion/react';
import MailRoundedIcon from '@mui/icons-material/MailRounded';
import MoveDownRoundedIcon from '@mui/icons-material/MoveDownRounded';
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import { AppNavProps } from 'app-navigation-ui';
import { useHistory } from 'react-router';

export const NavbarMenu = ({
  items,
}: {
  items: {
    label: React.ReactNode;
    icon: React.ReactNode;
    testId: string;
    onClick: () => void;
  }[];
}) => {
  return (
    <Flex css={{ width: '100%' }} gap={8}>
      {items.map((menu, idx) => (
        <Flex
          row
          gap={20}
          align="center"
          justify="start"
          testId={menu.testId}
          key={`menu-key-${idx + 99}`}
          css={MenuItemStyle}
          onClick={menu.onClick}>
          {menu.icon}
          {menu.label}
        </Flex>
      ))}
    </Flex>
  );
};

const MenuItemStyle = (theme: Theme) =>
  css({
    padding: 8,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.neutral['-3'],
    },
  });

export const useReliabilitySettingsItems = () => {
  const history = useHistory();
  const { location } = history;
  return [
    {
      label: 'User Preferences',
      icon: <TuneRoundedIcon />,
      testId: 'menu-user-preferences',
      onClick: () => {
        if (location.pathname === '/reliability/user-preferences') {
          history.go(0);
        }
        history.push('/reliability/user-preferences');
      },
    },
    {
      label: 'Email Subscriptions',
      icon: <MailRoundedIcon />,
      testId: 'menu-email-subscriptions',
      onClick: () => {
        if (location.pathname === '/reliability/email-subscriptions') {
          history.go(0);
        }
        history.push('/reliability/email-subscriptions');
      },
    },
  ];
};

export const useMonitorSettingsItems = () => {
  const history = useHistory();
  const { location } = history;
  return [
    {
      label: 'Layout Studio',
      icon: <MoveDownRoundedIcon />,
      testId: 'menu-layout-studio',
      onClick: () => {
        if (location.pathname === '/monitor/layout-studio') {
          history.go(0);
        }
        history.push('/monitor/layout-studio');
      },
    },
  ];
};

export const processMenu = (
  hierarchy: AppNavProps['apps'],
  menuItems: { id: string; sidebarSettings: AppNavProps['sidebarSettings'] }[],
) =>
  hierarchy.map(h => {
    const menuItem = menuItems.find(m => m.id === h.id);
    if (menuItem) {
      return { ...h, sidebarSettings: menuItem.sidebarSettings };
    }
    return h;
  });
