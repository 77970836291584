// SparkCognition Proprietary and Confidential.
// ©SparkCognition 2022-2023. All Rights Reserved.
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import DisplaySettingsRoundedIcon from '@mui/icons-material/DisplaySettingsRounded';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import EventNoteRoundedIcon from '@mui/icons-material/EventNoteRounded';
import HubRoundedIcon from '@mui/icons-material/HubRounded';
import LoyaltyRoundedIcon from '@mui/icons-material/LoyaltyRounded';
import ManageSearchRoundedIcon from '@mui/icons-material/ManageSearchRounded';
import PictureInPictureAltRoundedIcon from '@mui/icons-material/PictureInPictureAltRounded';
import SensorsRoundedIcon from '@mui/icons-material/SensorsRounded';
import SpeedRoundedIcon from '@mui/icons-material/SpeedRounded';
import TipsAndUpdatesRoundedIcon from '@mui/icons-material/TipsAndUpdatesRounded';
import { AppNavProps } from 'app-navigation-ui';

export const APPS_HIERARCHY = [
  {
    label: 'Monitor',
    id: 'monitor',
    modules: [
      {
        icon: <SpeedRoundedIcon />,
        label: 'Custom Analytics',
        id: 'data-dashboard',
        testId: 'data-dashboard-nav',
      },
      {
        icon: <DashboardRoundedIcon />,
        label: 'Dashboard Gallery',
        id: 'dashboard-gallery',
        testId: 'dashboard-gallery-nav',
        flag: 'beta',
      },
      {
        id: 'layout-studio',
        isInternal: true,
      },
    ],
  },
  {
    label: 'Predict',
    id: 'reliability',
    modules: [
      {
        icon: <HubRoundedIcon />,
        label: 'Model Overview',
        testId: 'model-behavior-nav',
        id: 'model-behavior',
      },
      {
        icon: <SensorsRoundedIcon />,
        label: 'Alert Logs',
        id: 'alert-logs',
        testId: 'alert-logs-nav',
      },
      {
        icon: <LoyaltyRoundedIcon />,
        label: 'Feature Health',
        testId: 'feature-health-nav',
        id: 'feature-health',
      },
      {
        icon: <EventNoteRoundedIcon />,
        label: 'Events',
        testId: 'events-management-nav',
        id: 'events',
      },
      {
        id: 'model-details',
        isInternal: true,
      },
      {
        id: 'user-preferences',
        isInternal: true,
      },
      {
        id: 'email-subscriptions',
        isInternal: true,
      },
    ],
  },
  {
    label: 'Prescribe',
    id: 'health',
    modules: [
      {
        icon: <TipsAndUpdatesRoundedIcon />,
        label: 'Operational Insights',
        testId: 'operational-insights-nav',
        id: 'plant-health',
      },
      {
        icon: <ManageSearchRoundedIcon />,
        label: 'Records Analysis',
        id: 'records-analysis',
        testId: 'records-analysis-nav',
      },
    ],
  },
  {
    label: 'View',
    id: 'system',
    modules: [
      {
        icon: <PictureInPictureAltRoundedIcon />,
        label: 'System Diagram',
        id: 'system-diagram',
        testId: 'system-diagram-mfe-nav',
        flag: 'beta',
      },
    ],
  },
  {
    label: 'Plan',
    id: 'planning',
    modules: [
      {
        icon: <DisplaySettingsRoundedIcon />,
        label: 'Optimizer',
        id: 'optimizer',
        testId: 'optimizer-mfe-nav',
        flag: 'alpha',
      },
    ],
  },
  {
    label: 'LLM Assistant',
    id: 'llm-assistant',
    modules: [
      {
        icon: <AutoAwesomeIcon />,
        label: 'AI Dialogue',
        id: 'ai-dialogue',
        testId: 'ai-dialogue-mfe-nav',
        flag: 'alpha',
      },
    ],
  },
] as AppNavProps['apps'];
