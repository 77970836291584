// SparkCognition Proprietary and Confidential.
// ©SparkCognition 2022. All Rights Reserved.
import { autorun, action, IReactionDisposer } from 'mobx';

// This is a lookup map for the kinds of storage APIs.
const storageMap: Record<string, Storage> = {
  local: localStorage,
  session: sessionStorage,
};

/**
 * This is defined by the Storable to tell us how to deal with the Storable
 * when loading and saving.
 */
export interface StorableConfig {
  key: string;
  type: 'local' | 'session';
}

/**
 * These are the properties that must be defined by the Storable.
 * storableConfig: property will contain the StorableConfig
 * storableJSON: property will contain the serialized string representation of the object.
 */
export interface Storable {
  storableConfig: StorableConfig;
  storableJSON: string;
}

/**
 * This will initialize the Storable by loading its properties from storage
 * and setting up an autorun.
 *
 * @param storable - the Storable to initialize
 */
export const initializeStorable = action(
  (storable: Storable): IReactionDisposer | null => {
    const config = storable.storableConfig;

    try {
      const storeStr = storageMap[config.type].getItem(config.key);
      if (storeStr) {
        const props = JSON.parse(storeStr);
        Object.assign(storable, props);
      }

      // Start auto-saving. Capture the disposer and return
      // in case the user wants to stop storing the storable automatically.
      const autosaveDisposer = autorun(() => {
        saveStorable(storable);
      });
      return autosaveDisposer;
    } catch (err) {
      console.log(
        `Unable to load from ${config.type} store using key ${config.key}: ${err}`,
      );
      return null;
    }
  },
);

/**
 * This will save the Storable to the location dictated by the Storable.
 *
 * @param storable - the Storable to be saved
 */
export const saveStorable = (storable: Storable): void => {
  const config = storable.storableConfig;
  try {
    storageMap[config.type].setItem(config.key, storable.storableJSON);
  } catch (err) {
    console.log(
      `Unable to save to ${config.type} store using key ${config.key}: ${err}`,
    );
  }
};
