// SparkCognition Proprietary and Confidential.
// ©SparkCognition 2022-2024. All Rights Reserved.
import { makeAutoObservable } from 'mobx';
import { intercept, addCSRFHeader } from '@core-ui/client/dist/fetch';
import {
  isPending,
  isFulfilled,
  isRejected,
  fulfilledValue,
  lazyObservablePromise,
  LazyObservablePromise,
} from '../lib/mobx/observablePromise';
import { OverrideStore } from './OverrideStore';

const SESSION_POLL_TIME = 5 * 60 * 1000; // 5 minutes

export interface IdToken {
  /* eslint-disable camelcase */
  acr: number;
  aio: string;
  'allowed-origins': [string];
  at_hash: string;
  aud: string;
  auth_time: string;
  azp: string;
  email: string;
  exp: number;
  family_name: string;
  given_name: string;
  iat: number;
  iss: string; // root url for the issuer, which points to the realm for oidc calls
  jti: string;
  name: string;
  nbf: number;
  nonce: string;
  oid: string;
  preferred_username: string;
  realm_access: Record<string, unknown>;
  resource_access: Record<string, unknown>;
  session_state: string;
  sub: string;
  tid: string;
  typ: string;
  ver: string;
}

const tokenFetch = intercept(fetch, [addCSRFHeader()]);

/**
 * This is the store that represents login and session state.
 */
export class SessionStore {
  overrideStore: OverrideStore;

  idToken: LazyObservablePromise<IdToken | null> = lazyObservablePromise(() =>
    this.dispatchTokenRetrievalAsync(),
  );

  idTokenRetrievalTimer?: ReturnType<typeof setTimeout>;

  get isAuthenticating(): boolean {
    return isPending(this.idToken.current());
  }

  get isAuthenticated(): boolean {
    return (
      isFulfilled(this.idToken.current()) &&
      this.idToken.current().value !== null
    );
  }

  get idTokenError(): Error | undefined {
    return isRejected(this.idToken.current())
      ? (this.idToken.current().value as Error)
      : undefined;
  }

  get firstName(): string | undefined {
    return fulfilledValue(this.idToken.current())?.given_name;
  }

  get lastName(): string | undefined {
    return fulfilledValue(this.idToken.current())?.family_name;
  }

  get email(): string | undefined {
    return fulfilledValue(this.idToken.current())?.email;
  }

  /**
   * Constructor for SessionStore.
   */
  constructor(overrideStore: OverrideStore) {
    makeAutoObservable(this, {
      idTokenRetrievalTimer: false,
    });
    this.overrideStore = overrideStore;
  }

  /**
   * Initialize the state of the store to empty.
   */
  clear = (): void => {
    // Cancel any running timer.
    if (this.idTokenRetrievalTimer) {
      clearTimeout(this.idTokenRetrievalTimer);
      delete this.idTokenRetrievalTimer;
    }

    // Clear the remaining props.
    this.idToken.reset();
  };

  /**
   * Initiate an IdToken retrieval from OAuth2 Proxy.
   */
  dispatchTokenRetrievalAsync = async (): Promise<IdToken | null> => {
    const response = await tokenFetch('/oauth2/user-info');
    if (response.ok) {
      this.startTokenRetrievalTimer();
      return response.json();
    } else if (response.status === 401) {
      return null;
    } else {
      return Promise.reject(
        new Error(`Session retrieval resulted in status ${response.status}`),
      );
    }
  };

  /**
   * Parse the IdToken.
   *
   * @param idTokenStr - the JWT string
   */
  // eslint-disable-next-line class-methods-use-this
  parseIdToken = (idTokenStr: string | null): IdToken | null => {
    if (idTokenStr) {
      const idToken: IdToken = JSON.parse(atob(idTokenStr.split('.')[1]));
      return idToken;
    }
    return null;
  };

  /**
   * Initiate a login attempt.
   */
  dispatchLogin = (): void => {
    // Start with a clean slate.
    this.clear();

    window.location.href = '/oauth2/sign-in';
  };

  /**
   * Initiate a logout.
   */
  // eslint-disable-next-line class-methods-use-this
  dispatchLogout = async (): Promise<void> => {
    // Open a browser to OIDC logout, which will in turn log out of the auth provider.
    window.location.href = '/oauth2/sign-out';
  };

  /**
   * Start the timer to check the session.
   */
  startTokenRetrievalTimer = (): void => {
    const sessionPollTime = this.overrideStore.overrideTime(
      'sessionPollTime',
      SESSION_POLL_TIME,
    );
    console.log(`refresh timer set for ${sessionPollTime}`);
    this.idTokenRetrievalTimer = setTimeout(() => {
      this.idToken.refresh();
    }, sessionPollTime);
  };
}
