// SparkCognition Proprietary and Confidential.
// ©SparkCognition 2022. All Rights Reserved.
import { SidebarProps } from '@core-ui/components';
import { Theme } from '@core-ui/theme';
import { makeAutoObservable } from 'mobx';
import { pick } from 'ramda';
import {
  Storable,
  StorableConfig,
  initializeStorable,
} from '../lib/store/Storable';

type SidebarItemProps = SidebarProps['items'][0];
export interface NavItem extends Pick<SidebarItemProps, 'label' | 'icon'> {
  path: string;
  defaultPath?: boolean;
  testId?: string;
}

/**
 * This is the store that represents the state of the left nav bar on pages.
 */
export class NavStore implements Storable {
  storeDomain: string;
  expanded: boolean = false;
  themeVariant: Theme['variant'] = 'light';

  get storableConfig(): StorableConfig {
    return {
      key: `${this.storeDomain}.NavStore`,
      type: 'local',
    };
  }

  get storableJSON(): string {
    return JSON.stringify(pick(['expanded', 'themeVariant'], this));
  }

  constructor(storeDomain: string) {
    this.storeDomain = storeDomain;

    // Configure mobx.
    makeAutoObservable(this);

    // Load and setup autorun.
    initializeStorable(this);
  }

  setExpanded = (expanded: boolean): void => {
    this.expanded = expanded;
  };

  toggleTheme = (): void => {
    this.themeVariant = this.themeVariant === 'light' ? 'dark' : 'light';
  };
}
