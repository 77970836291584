// SparkCognition Proprietary and Confidential.
// ©SparkCognition 2022. All Rights Reserved.
import { makeAutoObservable } from 'mobx';
import { pick } from 'ramda';
import {
  Storable,
  StorableConfig,
  initializeStorable,
} from '../lib/store/Storable';

/**
 * This is the store that represents system overrides.
 */
export class OverrideStore implements Storable {
  storeDomain: string;
  sessionPollTime?: number;
  autologoutWarningTime?: number;
  autologoutLogoutTime?: number;

  get storableConfig(): StorableConfig {
    return {
      key: `${this.storeDomain}.OverrideStore`,
      type: 'session',
    };
  }

  get storableJSON(): string {
    return JSON.stringify(
      pick(Object.keys(this).filter(key => key !== 'storeDomain') as any, this),
    );
  }

  overrideTime(
    key: Extract<
      keyof OverrideStore,
      'sessionPollTime' | 'autologoutWarningTime' | 'autologoutLogoutTime'
    >,
    normally: number,
  ): number {
    const overrideValue = this[key];
    if (overrideValue !== undefined && overrideValue < normally) {
      return overrideValue;
    } else {
      return normally;
    }
  }

  constructor(storeDomain: string) {
    this.storeDomain = storeDomain;

    // Configure mobx.
    makeAutoObservable(this);

    // Load and setup autorun.
    initializeStorable(this);
  }
}
