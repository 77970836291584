// SparkCognition Proprietary and Confidential.
// ©SparkCognition 2022-2023. All Rights Reserved.
import { Authenticated, GenericLoginCTA } from '@core-ui/client';
import { AuthenticatedApp } from './AuthenticatedApp';

export const App = () => {
  return (
    <Authenticated fallback={<GenericLoginCTA />}>
      <AuthenticatedApp />
    </Authenticated>
  );
};
